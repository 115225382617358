import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { useToast } from '@chakra-ui/react'
import WeightUnitsModal from "./WeightUnitsModal";
import { loginuser, googleAuth, fbAuth, signUp } from "app/auth/auth.action";
import { GetUser } from "app/user/user.action";
import {
    InputGroup,
    Input,
    InputRightElement,
    Button,
    Checkbox
} from '@chakra-ui/react';
import { ClipLoader } from "react-spinners";
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


import ProteinServingImg from '../assets/protien-serving.svg';
import FatsServingImg from '../assets/fats-img.svg';
import VegServingImg from '../assets/veg-serving.svg';
import FruitServingImg from '../assets/fruit-serving.jpg';
import StarchServingImg from '../assets/starch-img.svg';
import SuccessImg from '../assets/success icon component.png';


import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import GoogleIcon from '../assets/Google.svg';
import { AiFillFacebook } from "react-icons/ai";
import { Onboarding } from "app/user/user.action";
import OtpModal from "./OtpModal";

const OnboardStepper = () => {
    const [step, setStep] = useState(1);
    const [isOtpOpen, setIsOtpOpen] = useState(false);

    let localObject: any = JSON.parse(localStorage.getItem("StepperData") || '{}')
    let token: any = localStorage.getItem('accesstoken');
    let signup: any = localStorage.getItem("signup");

    // steps states
    const [Gender, setGender] = useState('');
    const [Race, setRace] = useState('');
    const [heightFeet, setHeightFeet] = useState();
    const [heightInches, setHeightInches] = useState();
    const [weight, setWeight] = useState<string>('');
    const [weightUnits, setWeightUnits] = useState('kg');
    const [processedSugar, setProcessedSugar] = useState('');
    const [proteinServing, setProteinServing] = useState('');
    const [proteinSource, setProteinSource] = useState('');
    const [fatsServing, setFatsServing] = useState('');
    const [fatsSource, setFatsSource] = useState<any>('');
    const [vegServing, setVegServing] = useState('');
    const [fruitServing, setFruitServing] = useState('');
    const [starchServing, setStarchServing] = useState('');
    const [waterCups, setWaterCups] = useState('');
    const [sleepHours, setSleepHours] = useState('');
    const [stressLevels, setStressLevels] = useState('');
    const [avgExercise, setAverageExercise] = useState('');
    const [userName, setUserName] = useState('');
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);



    const gender = ['Male', 'Female'];
    const race = ['Hispanic/Latino', 'American Indian or Alaska Native', 'Asian', 'Black or African American', 'Native Hawaiian or Other Pacific Islander ', 'White/Caucasian']
    const howOften = ['Never', '1-2', '3-4', '5-6', '7+']
    const serving = ['None', '1-2', '3-4', '5-6', '7+']
    const proteinSources = ['Plant-based', 'Animal-based']
    const primaryFatsSource = [
        {
            name: "Saturated fats",
            desc: "(e.g.: hard cheeses, ghee, suet, lard, red meat, bacon, butter, cured meats (salami, chorizo, pancetta), highly processed or sugary foods (cakes, biscuits), fast food, coconut oil) butter, ghee, suet, lard, coconut oil and palm oil"
        },
        {
            name: "Polyunsaturated fats",
            desc: "(e.g.: walnuts, sunflower oil, almonds, tofu, pumpkin seeds, avocado oil, rapeseed oil, cold-water fatty fish (mackerel, salmon, tuna)"
        },
        {
            name: "Monounsaturated fats",
            desc: "(e.g., olive oil, cold-water fish (salmon, tuna), almonds, olive oil, canola oil, peanut oil, sesame oil, safflower oil, avocados, peanut butter, soybeans, and nuts and seeds)."
        },
        {
            name: "Unsaturated fats",
            desc: "(e.g., plant oils (olive, canola, soybean), some nuts and seeds, avocados, cold-water fatty fish, and dark chocolate)."
        },
    ]
    const vegFruitStarchServing = ['None', '0-2 Serving', '3-4 Serving', '5-6 Serving', '6+ Serving']
    const cupsOfWater = ['None', '0-3 cups', '4-7 cups', '8-11 cups', '12-15 cups', '15+ cups']
    const sleep = ['1-3 hours', '4-6 hours', '7-9 hours', '9+ hours']
    const stressLevel = ['Very low', 'Some stress', 'Medium levels of stress', 'High levels of stress', 'Extreme stress']
    const exercise = ['None (Sedentary)', 'Little exercise (Sedentary: little to no exercise)', 'Light exercise (1-3 times per week)', 'Moderate exercise (4-5 times per week)', 'Daily or Intensive exercise (3-4 times per week 30-45 minutes)', 'Very Intensive exercise (6-7 times per week 30-45 minutes)']

    const handleGender = (value: any) => setGender(value)
    const handleRace = (value: any) => setRace(value)
    const handleHeightFeet = (value: any) => setHeightFeet(value)
    const handleHeightInches = (value: any) => setHeightInches(value)
    const handleWeight = (value: any) => setWeight(value)
    const handleProcessedSugar = (value: any) => setProcessedSugar(value);
    const handleProteinServing = (value: any) => setProteinServing(value);
    const handleProteinSource = (value: any) => setProteinSource(value);
    const handleFatsServing = (value: any) => setFatsServing(value);
    const handleFatsSource = (value: any) => setFatsSource(value);
    const handleVegServing = (value: any) => setVegServing(value);
    const handleFruitServing = (value: any) => setFruitServing(value);
    const handleStarchServing = (value: any) => setStarchServing(value);
    const handleWaterCups = (value: any) => setWaterCups(value);
    const handleSleepHours = (value: any) => setSleepHours(value);
    const handleStressLevel = (value: any) => setStressLevels(value);
    const handleExercise = (value: any) => setAverageExercise(value);
    const handleClick = () => setShow(!show);

    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthData = useSelector((state: any) => state.auth);

    const handleNext = async () => {
        if (step === 1 && (!Gender || !Race)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }

        else if (step === 1 && Gender && Race) {
            localObject.gender = Gender;
            localObject.race = Race;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }

        else if (step === 2 && (!heightFeet || !heightInches || !weight)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }

        else if (step === 2 && heightFeet && heightInches && weight) {
            localObject.height = `${heightFeet}.${heightInches}`
            const newWeight = weight.concat(weightUnits);
            console.log(newWeight);
            localObject.weight = newWeight;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }

        else if (step === 3 && (!processedSugar)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 3 && processedSugar) {
            localObject.processedFoodFrequency = processedSugar;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 4 && (!proteinServing || !proteinSource)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 4 && proteinServing && proteinSource) {
            localObject.protienConsumption = proteinServing;
            localObject.protienSource = proteinSource;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 5 && (!fatsServing || !fatsSource)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 5 && fatsServing && fatsSource) {
            localObject.fatConsumption = fatsServing;
            const newFatsSource = `${fatsSource.name} ${fatsSource.desc}`
            localObject.fatSource = newFatsSource
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 6 && (!vegServing)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 6 && vegServing) {
            localObject.vegetableConsumption = vegServing;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 7 && (!fruitServing)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 7 && fruitServing) {
            localObject.fruitConsumption = fruitServing;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 8 && (!starchServing)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 8 && starchServing) {
            localObject.strachesConsumption = starchServing;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 9 && (!waterCups)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 9 && waterCups) {
            localObject.waterConsumption = waterCups;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 10 && (!sleepHours)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 10 && sleepHours) {
            localObject.sleepDuration = sleepHours;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 11 && (!stressLevels)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 11 && stressLevels) {
            localObject.stressLevel = stressLevels;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            setStep(step + 1);
        }
        else if (step === 12 && (!avgExercise)) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 12 && avgExercise) {
            localObject.exercise = avgExercise;
            localStorage.setItem("StepperData", JSON.stringify(localObject));
            if (token) {
                setStep(step + 2);
            }
            else {
                setStep(step + 1);
            }
        }
        else if (step === 13 && (!firstName || !lastName || !email || password === '')) {
            toast({
                description: "Please Select the missing options!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 14 && !userName) {
            toast({
                description: "Please enter a username!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else if (step === 14 && userName) {
            try {
                setLoading(true);
                localObject.userName = userName;
                localStorage.setItem("StepperData", JSON.stringify(localObject));
                const result = await dispatch(Onboarding(localObject) as any);
                localStorage.removeItem("signup")
                localStorage.removeItem("StepperData")
                if (result.payload) {
                    setLoading(false)
                    // navigate("/user/dashboard")
                    setStep(15);
                }
                else {
                    setLoading(false)
                    toast({
                        description: "please Signup properly!",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            }
            catch (error: any) {
                setLoading(false)
                toast({
                    description: error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        }

        else if (step < 14) setStep(step + 1);
    };

    const handleBack = () => {
        if (step === 14 && token) setStep(step - 2)
        else if (step > 1) setStep(step - 1);
    };

    const progressPercentage = ((step - 1) / 13) * 100;

    const handleSignup = async () => {
        localStorage.setItem("signup", "true");

        if (firstName === '' || lastName === '' || password === '' || email === '') {
            toast({
                description: "Please enter all the details!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return;
        }
        else if (password.length < 10) {
            toast({
                description: "password must be of minimun 10 characters!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return;
        }

        try {
            const result = await dispatch(signUp(firstName, lastName, email, password)) as any;

            if (result.success) {
                toast({
                    description: "OTP has been sent to your account!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                setIsOtpOpen(true)
            } else {
                toast({
                    description: result.error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (error: any) {
            toast({
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    };


    const handleGoogleAuth = async () => {
        localStorage.setItem("signup", "true");
        try {
            const result = await dispatch(googleAuth() as any)
            if (result.startsWith('http')) {
                window.location.href = result;
            }
        }
        catch (error: any) {

        }
    }

    const handleFbAuth = async () => {
        localStorage.setItem("signup", "true");
        try {
            const result = await dispatch(fbAuth() as any)
            if (result.startsWith('http')) {
                window.location.href = result;
            }
        }
        catch (error: any) {

        }
    }

    const handleExplore = () => {
        navigate('/user/dashboard');
    }

    useEffect(() => {
        if (signup === "true") {
            setStep(14);
        }
    }, [])

    useEffect(() => {
        const check = async () => {
            const checking = await dispatch(GetUser() as any);
            if (checking?.payload?.isonboarded) { navigate("/user/dashboard") }
        }

        check();
    }, [])

    return (
        <div className="min-w-screen min-h-screen mx-auto px-20 py-20 bg-gradient-to-r from-[#232323] to-[#507C37]">
            <OtpModal isOpen={isOtpOpen} onClose={() => setIsOtpOpen(false)} email={email} />
            <div className="w-[672px] mx-auto bg-white rounded-[8px] text-black" >
                <ProgressBar
                    percent={progressPercentage}
                    filledBackground="#A2D800"
                >
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`step ${accomplished ? "completed" : ""}`}>

                            </div>
                        )}
                    </Step>
                </ProgressBar>

                <div className="mt-10 p-5">
                    {step === 15 && <Step15 handleExplore={handleExplore} />}
                    {step === 1 && <Step1 gender={gender} race={race} handleGender={handleGender} Gender={Gender} Race={Race} handleRace={handleRace} />}
                    {step === 2 && <Step2 heightFeet={heightFeet} heightInches={heightInches} handleHeightFeet={handleHeightFeet} handleHeightInches={handleHeightInches} weight={weight} handleWeight={handleWeight} weightUnits={weightUnits} setWeightUnits={setWeightUnits} />}
                    {step === 3 && <Step3 howOften={howOften} processedSugar={processedSugar} handleProcessedSugar={handleProcessedSugar} />}
                    {step === 4 && <Step4 serving={serving} proteinServing={proteinServing} proteinSources={proteinSources} proteinSource={proteinSource} handleProteinSource={handleProteinSource} handleProteinServing={handleProteinServing} />}
                    {step === 5 && <Step5 serving={serving} fatsServing={fatsServing} handleFatsServing={handleFatsServing} primaryFatsSource={primaryFatsSource} fatsSource={fatsSource} handleFatsSource={handleFatsSource} />}
                    {step === 6 && <Step6 vegFruitStarchServing={vegFruitStarchServing} handleVegServing={handleVegServing} vegServing={vegServing} />}
                    {step === 7 && <Step7 vegFruitStarchServing={vegFruitStarchServing} handleFruitServing={handleFruitServing} fruitServing={fruitServing} />}
                    {step === 8 && <Step8 vegFruitStarchServing={vegFruitStarchServing} handleStarchServing={handleStarchServing} starchServing={starchServing} />}
                    {step === 9 && <Step9 cupsOfWater={cupsOfWater} handleWaterCups={handleWaterCups} waterCups={waterCups} />}
                    {step === 10 && <Step10 sleep={sleep} handleSleepHours={handleSleepHours} sleepHours={sleepHours} />}
                    {step === 11 && <Step11 stressLevel={stressLevel} handleStressLevel={handleStressLevel} stressLevels={stressLevels} />}
                    {step === 12 && <Step12 exercise={exercise} handleExercise={handleExercise} avgExercise={avgExercise} />}
                    {step === 13 && <Step13 firstName={firstName} lastName={lastName} setFirstName={setFirstName} setLastName={setLastName} show={show} password={password} setPassword={setPassword} handleClick={handleClick} email={email} setEmail={setEmail} handleSignup={handleSignup} handleGoogleAuth={handleGoogleAuth} handleFbAuth={handleFbAuth} AuthData={AuthData} />}
                    {step === 14 && <Step14 userName={userName} setUserName={setUserName} />}

                        {
                            step !== 15
                            &&
                            <div className="flex items-center justify-center mt-5">
                        <button
                            onClick={handleBack}
                            className={`px-4 py-2  w-[159px] h-[58px] border-2 border-[#253200] font-semibold rounded-[15px] text-[#253200] mx-2 rounded ${step === 1 ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                            disabled={step === 1}
                        >
                            BACK
                        </button>
                        <button
                            onClick={handleNext}
                            className={`px-4 py-2 w-[159px] h-[58px] bg-gradient-to-r from-[#232323] to-[#507C37] mx-2 text-white rounded-[15px] 
                                }`}
                            // disabled={step === 14}
                            disabled={loading}
                        >
                            {
                                step === 14 ? loading ? <ClipLoader color="white" size={25} /> : "FINISH" : "NEXT"
                            }
                        </button>
                    </div>
                        }
                </div>
            </div>
        </div>
    );
};

const Step1 = ({ gender, race, handleGender, Gender, Race, handleRace }: { gender: string[], race: string[], handleGender: any, Gender: String, Race: String, handleRace: any }) => (
    <div>
        <h2 className="text-[32px] font-medium text-center">Tell us a little bit about yourself</h2>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >Please select which gender we should use to calculate your calorie needs:</p>
        <div className="flex items-center justify-between px-5" >
            {
                gender?.map((elem: any) => {
                    return (
                        <div onClick={() => handleGender(elem)} className={`border-2 border-[#EDEDED] w-[50%] h-[58px] flex items-center justify-center font-semibold cursor-pointer ${elem === Gender ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>

        <p className="my-4 w-full md:w-[75%] font-medium px-5" >Please select your race:</p>
        <div className="px-5" >
            {
                race.map((elem: any) => {
                    return (
                        <div onClick={() => handleRace(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === Race ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);

const Step2 = ({ heightFeet, heightInches, handleHeightFeet, handleHeightInches, weight, handleWeight, weightUnits, setWeightUnits }: { heightFeet: number, heightInches: number, handleHeightFeet: any, handleHeightInches: any, weight: string, handleWeight: any, weightUnits: string, setWeightUnits: any }) => {
    const [isWeightModal, setIsWeightModal] = useState(false);
    return (
        <div>
            <p className="my-4 w-full md:w-[75%] font-medium px-5" >How tall are you?</p>
            <div className="flex ite,s-center justify-between w-full px-5">
                <div className="relative w-full">
                    <input type="number" value={heightFeet} onChange={(e) => handleHeightFeet(e.target.value)} placeholder="Height (feet)" className={`border-2 border-[#EDEDED] w-[97%] h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer px-4`} />
                    <p className="font-bold text-[#7B7B7B] absolute right-[24px] top-[21px] text-[17px]" >ft</p>
                </div>
                <div className="relative w-full" >
                    <input type="number" value={heightInches} onChange={(e) => handleHeightInches(e.target.value)} placeholder="Height (inches)" className={`border-2 border-[#EDEDED] w-[97%] h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer px-4`} />
                    <p className="font-bold text-[#7B7B7B] absolute right-[24px] top-[21px] text-[17px]" >in</p>
                </div>
            </div>

            <p className="my-4 w-full md:w-[75%] font-medium px-5" >How much do you weigh?</p>
            <div className="px-5" >
                <div className="relative" >
                    <input type="text" value={weight} onChange={(e) => handleWeight(e.target.value)} placeholder="Current weight" className={`border-2 border-[#EDEDED] w-[48%] h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer px-4`} />
                    <p className="font-bold text-[#7B7B7B] absolute right-[44px] top-[16px] text-[17px] w-[50%]" >
                        {weightUnits === "lb" ? "lb" : "kg"}
                    </p>
                </div>
                <p onClick={() => setIsWeightModal(true)} className="font-medium text-[#465F00] cursor-pointer mt-4 inline-block" >Change units to kilograms</p>
            </div>

            <WeightUnitsModal isOpen={isWeightModal} onClose={() => setIsWeightModal(false)} weightUnits={weightUnits} setWeightUnits={setWeightUnits} />
        </div>
    )
};

const Step3 = ({ howOften, processedSugar, handleProcessedSugar }: { howOften: string[], processedSugar: string, handleProcessedSugar: any }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >How often a week do you eat out or eat highly processed (sugary and salty foods) or prepackaged/frozen meals?</p>
        <div className="flex flex-col px-5" >
            {
                howOften?.map((elem: any) => {
                    return (
                        <div onClick={() => handleProcessedSugar(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === processedSugar ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step4 = ({ serving, handleProteinServing, proteinServing, proteinSource, proteinSources, handleProteinSource }: { serving: string[], handleProteinServing: any, proteinServing: string, proteinSource: string, proteinSources: string[], handleProteinSource: any }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >How many servings of protein do you eat daily? <br />(eg: 1 serving of protein = 1 palm size or 3 oz).</p>
        <div className="flex justify-center mb-2" >
            <img src={ProteinServingImg} alt="protein-serving-img" />
        </div>
        <div className="flex flex-col px-5" >
            {
                serving?.map((elem: any) => {
                    return (
                        <div onClick={() => handleProteinServing(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === proteinServing ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >Are your daily sources of protein primarily plant-based or animal-based?</p>
        <div className="flex flex-col px-5" >
            {
                proteinSources?.map((elem: any) => {
                    return (
                        <div onClick={() => handleProteinSource(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === proteinSource ? "bg-green-700 text-white" : ""}`} >
                            {elem}
                        </div>
                    )
                })
            }
        </div>
    </div>
);
const Step5 = ({ serving, handleFatsServing, fatsServing, primaryFatsSource, fatsSource, handleFatsSource }: { serving: string[], handleFatsServing: any, fatsServing: string, primaryFatsSource: any, fatsSource: any, handleFatsSource: any }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >How many servings of fats do you eat daily? <br /> (e.g., 1 serving of fat = size of the tip of your thumb).</p>
        <div className="flex justify-center mb-2" >
            <img src={FatsServingImg} alt="protein-serving-img" />
        </div>
        <div className="flex flex-col px-5" >
            {
                serving?.map((elem: any) => {
                    return (
                        <div onClick={() => handleFatsServing(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === fatsServing ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >What are your primary daily sources of fats?</p>
        <div className="flex flex-col px-5" >
            {
                primaryFatsSource?.map((elem: any) => {
                    return (
                        <div onClick={() => handleFatsSource(elem)} className={`border-2 border-[#EDEDED] w-full min-h-[130px] my-1 flex flex-col font-semibold cursor-pointer ${elem.name === fatsSource.name ? "bg-green-700 text-white" : ""}`} >
                            <p className="my-2 w-full md:w-[75%] font-bold px-5" >{elem.name}</p>
                            <p className="my-2 font-medium px-5" >{elem.desc}</p>
                        </div>
                    )
                })
            }
        </div>
    </div>
);
const Step6 = ({ vegFruitStarchServing, handleVegServing, vegServing }: { vegFruitStarchServing: string[], handleVegServing: any, vegServing: string }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >How many servings of vegetables do you eat daily? <br />(e.g., 1 serving of vegetables = 1 cup or two hands cupped together)</p>
        <div className="flex justify-center mb-2" >
            <img src={VegServingImg} alt="protein-serving-img" />
        </div>
        <div className="flex flex-col px-5" >
            {
                vegFruitStarchServing?.map((elem: any) => {
                    return (
                        <div onClick={() => handleVegServing(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === vegServing ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step7 = ({ vegFruitStarchServing, handleFruitServing, fruitServing }: { vegFruitStarchServing: string[], handleFruitServing: any, fruitServing: string }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >How many servings of fruit do you eat daily? <br /> (e.g., 1 medium serving of fruit = 1 fistful or 1 cup of strawberries – 12 berries)</p>
        <div className="flex justify-center mb-2" >
            <img src={FruitServingImg} alt="protein-serving-img" />
        </div>
        <div className="flex flex-col px-5" >
            {
                vegFruitStarchServing?.map((elem: any) => {
                    return (
                        <div onClick={() => handleFruitServing(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === fruitServing ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step8 = ({ vegFruitStarchServing, handleStarchServing, starchServing }: { vegFruitStarchServing: string[], handleStarchServing: any, starchServing: string }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >How many servings of starches do you eat daily?<br />(e.g., 1 serving = 1 fistful)</p>
        <div className="flex justify-center mb-2" >
            <img src={StarchServingImg} alt="protein-serving-img" />
        </div>
        <div className="flex flex-col px-5" >
            {
                vegFruitStarchServing?.map((elem: any) => {
                    return (
                        <div onClick={() => handleStarchServing(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === starchServing ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step9 = ({ cupsOfWater, handleWaterCups, waterCups }: { cupsOfWater: string[], handleWaterCups: any, waterCups: any }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >How many cups of water do you drink daily? <br /> (eg: 1 US cup = 8 fluid oz.).</p>
        <div className="flex flex-col px-5" >
            {
                cupsOfWater?.map((elem: any) => {
                    return (
                        <div onClick={() => handleWaterCups(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === waterCups ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step10 = ({ sleep, handleSleepHours, sleepHours }: { sleep: string[], handleSleepHours: any, sleepHours: string }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >On average how many hours a night do you sleep?</p>
        <div className="flex flex-col px-5" >
            {
                sleep?.map((elem: any) => {
                    return (
                        <div onClick={() => handleSleepHours(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === sleepHours ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step11 = ({ stressLevel, handleStressLevel, stressLevels }: { stressLevel: string[], handleStressLevel: any, stressLevels: string }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >On a scale of 1-5 how would you rate your stress levels <br /> (eg, 1 very low, 5 very high)</p>
        <div className="flex flex-col px-5" >
            {
                stressLevel?.map((elem: any) => {
                    return (
                        <div onClick={() => handleStressLevel(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === stressLevels ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step12 = ({ exercise, handleExercise, avgExercise }: { exercise: string[], handleExercise: any, avgExercise: string }) => (
    <div>
        <p className="my-4 w-full md:w-[75%] font-medium px-5" >On average, how much exercise do you get every week? <br />(e.g., at least 30 minutes per day – walking, cardiovascular and/or strength training, weight training).</p>
        <div className="flex flex-col px-5" >
            {
                exercise?.map((elem: any) => {
                    return (
                        <div onClick={() => handleExercise(elem)} className={`border-2 border-[#EDEDED] w-full h-[58px] my-1 flex items-center justify-center font-semibold cursor-pointer ${elem === avgExercise ? "bg-green-700 text-white" : ""}`} >{elem}</div>
                    )
                })
            }
        </div>
    </div>
);
const Step13 = ({ firstName, lastName, setFirstName, setLastName, show, password, setPassword, handleClick, email, setEmail, handleSignup, handleGoogleAuth, handleFbAuth, AuthData }: { firstName: string, lastName: string, setFirstName: any, setLastName: any, show: boolean, password: string, setPassword: any, handleClick: any, email: string, setEmail: any, handleSignup: any, handleGoogleAuth: any, handleFbAuth: any, AuthData: any }) => {
    return (
        <div>
            <h2 className="text-[32px] font-medium text-center">Almost there! Create your account.</h2>
            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" className={`border-2 border-[#EDEDED] w-[97%] h-[58px] my-2 rounded-[13px] flex items-center justify-center font-semibold cursor-pointer px-4`} />
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" className={`border-2 border-[#EDEDED] w-[97%] h-[58px] my-2 rounded-[13px] flex items-center justify-center font-semibold cursor-pointer px-4`} />
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className={`border-2 border-[#EDEDED] w-[97%] h-[58px] my-2 rounded-[13px] flex items-center justify-center font-semibold cursor-pointer px-4`} />
            <InputGroup size='md' border="2px solid #E2E2E2" borderRadius="13px" width="97%" height="58px">
                <Input
                    type={show ? 'text' : 'password'}
                    placeholder='Enter password'
                    border="none"
                    onChange={(e) => setPassword(e.target.value)}
                    borderRadius="13px" width="97%" height="58px"
                    _focus={{ outline: 'none', boxShadow: 'none' }}
                    _placeholder={{ fontWeight: "600" }}
                />
                <InputRightElement width='4rem' paddingTop="15px">
                    <Button bg="#fff" _hover={{ bg: "#fff" }} h='1.75rem' size='sm' onClick={handleClick} paddingLeft="30px">
                        {show ? <AiFillEyeInvisible className='text-[17px]' /> : <AiFillEye className='text-[17px]' />}
                    </Button>
                </InputRightElement>
            </InputGroup>

            <div className="flex flex-col justify-start" >
                <small className="text-[#696969] font-medium" >Must be at least 10 characters, no spaces.</small>

                <small className="my-6 text-[#696969] font-medium" >This site is protected by reCAPTCHA and the Google Privacy Policy and Terms apply.</small>
                <Checkbox colorScheme='green' defaultChecked>
                    <div className="flex flex-col justify-start ms-3" >
                        <p className="my-1 w-full md:w-[75%] font-bold" >Terms & Conditions</p>
                        <small className="text-[#696969] font-medium" >I agree to Live100’s Terms & Conditions and Privacy Policy apply.</small>
                    </div>
                </Checkbox>
            </div>

            <Button width="100%" margin="15px 0" height="58px" onClick={handleSignup} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="10px" marginBottom="14px" color="#fff" padding="13px 20px" className="font-semibold" >
                {
                    AuthData.loading ?
                        <ClipLoader color="white" size={25} />
                        :
                        "Continue"
                }
            </Button>
            <p className='text-center text-[#000]' >or</p>

            <Button width="100%" height="58px" onClick={handleGoogleAuth} display="flex" justifyContent="justify-between" alignItems="center" bg="#fff" borderRadius="10px" margin="14px 0" color="#000" padding="13px 20px" className="font-semibold" border="2px solid #E2E2E2">
                {
                    AuthData.GLoading ?
                        <ClipLoader color="#8a8a8a" size={25} />
                        :
                        <>
                            <img src={GoogleIcon} alt="google" width={"5%"} />
                            <p className='w-[90%]' >
                                Continue with Google
                            </p>
                        </>
                }
            </Button>
            <Button width="100%" height="58px" onClick={handleFbAuth} display="flex" justifyContent="justify-between" alignItems="center" bg="#4267B2" _hover={{ bg: "#4267B2" }} borderRadius="10px" margin="14px 0" color="#fff" padding="13px 20px" className="font-semibold" >
                {
                    AuthData.FBLoading ?
                        <ClipLoader color="#8a8a8a" size={25} />
                        :
                        <>
                            <AiFillFacebook className='w-[6%] text-[20px]' />
                            <p className='w-[90%]'>
                                Continue with Facebook
                            </p>
                        </>
                }
            </Button>

        </div>
    )
};
const Step14 = ({ userName, setUserName }: { userName: string, setUserName: any }) => (
    <div>
        <h2 className="text-[32px] font-medium text-center">One last step</h2>
        <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Create a username" className={`border-2 border-[#EDEDED] w-[97%] h-[58px] my-1 rounded-[13px] flex items-center justify-center font-semibold cursor-pointer px-4`} />
    </div>
);

const Step15 = ({handleExplore} : {handleExplore:any}) => {
    const [isConfettiVisible, setIsConfettiVisible] = useState(true);
     useEffect(() => {
    const timer = setTimeout(() => {
      setIsConfettiVisible(false);
    }, 6000); 

    return () => clearTimeout(timer);
  }, []);
return(
    <div>
         {isConfettiVisible && (
        <Confetti
          width={1600}
          height={800}
          tweenDuration={5000}
        />
      )}
        <img src={SuccessImg} alt="" className="text-center my-3 mx-auto" />
        <h2 className="text-[48px] font-bold text-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">Congratulations!</h2>
        <p className="text-center" >Your daily net calorie goal is:</p>
        <h2 className="text-[48px] font-bold text-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">1,200</h2>
        <div className="mx-auto px-3 text-center py-2 bg-gray-100 w-[100px]">
            <small className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent font-semibold" >Calories</small>
        </div>

        <small className="block text-center mt-5">With this plan, you should:</small>
        <p className="text-center"><strong>Lose 5kg</strong> by <strong>November 26</strong></p>

        <div className="mt-5 py-6 border-t">
            <Checkbox colorScheme='green' defaultChecked alignItems="start">
                <div className="flex flex-col justify-between items-startu ms-3" >
                    <p className="mt-[-6px] w-full md:w-[75%] font-bold" >Sign up for emails <small className="bg-gradient-to-l from-[#507C37] to-[#232323] text-white rounded-[5px] p-1" >recommended</small> </p>
                    <small className="text-[#696969] font-medium mt-2" >Get recipes, workouts, and nutrition & fitness tips from Live100 AI Agents. Plus, a first look at new featurs!</small>
                </div>
            </Checkbox>
            <button onClick={handleExplore} className={`px-4 py-2 w-full mt-3 text-center h-[58px] bg-gradient-to-r from-[#232323] to-[#507C37] text-white rounded-[15px] font-bold`}>
                EXPLORE LIVE100
            </button>
        </div>

    </div>
)
}

export default OnboardStepper;