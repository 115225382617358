import React from "react";
import { useState , useEffect} from 'react';
import { Link, useLocation , useNavigate } from "react-router-dom";
import { useDispatch , useSelector} from "react-redux";
import { Box, Flex, Heading, Button, useDisclosure, IconButton, Stack, Collapse } from '@chakra-ui/react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import { FaBars, FaTimes } from 'react-icons/fa';
import { GoBell } from "react-icons/go";
import darkLogo from '../assets/dark-logo.png'
import DashboardIcon from '../assets/dashboard-nv-icon.svg'
import { GetUser } from "app/user/user.action";
import PlanTableModal from "./PlanTableModal";
import { Session } from "app/stripe/stripe.action";
import { ClipLoader } from "react-spinners";



const UserNavbar = () => {
    const { isOpen, onToggle } = useDisclosure();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const User = useSelector((state:any) => state.user);
    const Stripe = useSelector((state:any) => state.stripe);

    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);

    const [stripeModal , setStripeModal] = useState(false);

    // Handlers to toggle between modals
    const openLoginModal = () => {
        setIsLoginOpen(true);
        setIsSignupOpen(false);
    };

    const openSignupModal = () => {
        setIsSignupOpen(true);
        setIsLoginOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem("accesstoken");
        navigate("/");
    }

    const handleBillings = async () => {
        const result = await dispatch(Session() as any);
        if(result.payload.includes('http')){
            window.location.href = result.payload
        }
    }

    const handleAccess = (target:any) => {
        if(User?.user?.subscriptionInfo?.planName === "free"){
            setStripeModal(true)
        }
        else{
            navigate(target);
        }
    }

    useEffect(() => {
        dispatch(GetUser() as any); 
      },[])
    return (
        <>
            <Box py={{ base: 5, md: 4 }} px={20} background="#fff">
                <Flex h={16} alignItems="center" justifyContent="space-between">
                    {/* Logo on the left */}
                    <Heading size="md">
                        <img src={darkLogo} alt="logo" />
                    </Heading>

                    {/* Centered Menu Items */}
                    <Flex
                        display={{ base: 'none', md: 'flex' }}
                        flexGrow={1}
                        justifyContent="start"
                        alignItems="center"
                        margin="0 60px"
                    >
                        <Link to="/user/dashboard" className={`px-5 py-2 rounded-xl text-[#7B7B7B] font-semibold flex items-center ${location.pathname === "/user/dashboard" ? "bg-gradient-to-r from-[#2D3728] to-[#507C37] text-white" : ""}`}>
                            <img src={DashboardIcon} alt="dashboarx-icon" className="mr-1" />
                            Dashboard
                        </Link>
                        <Link to="" onClick={() => handleAccess("/user/food")} className={`px-5 py-2 rounded-xl text-[#7B7B7B] font-semibold flex items-center ${location.pathname === "/user/food" ? "bg-gradient-to-r from-[#2D3728] to-[#507C37] text-white" : ""} `}>
                            <img src={DashboardIcon} alt="dashboarx-icon" />
                            Food
                        </Link>
                        <Link to="" onClick={() => handleAccess("/user/exercise")} className={`px-5 py-2 rounded-xl text-[#7B7B7B] font-semibold flex items-center ${location.pathname === "/user/exercise" ? "bg-gradient-to-r from-[#2D3728] to-[#507C37] text-white" : ""} `}>
                            <img src={DashboardIcon} alt="dashboarx-icon" />
                            Exercise
                        </Link>
                        <Link to="" onClick={() => handleAccess("/user/wellness")} className={`px-5 py-2 rounded-xl text-[#7B7B7B] font-semibold flex items-center ${location.pathname === "/user/wellness" ? "bg-gradient-to-r from-[#2D3728] to-[#507C37] text-white" : ""} `}>
                            <img src={DashboardIcon} alt="dashboarx-icon" />
                            Wellness
                        </Link>
                        <Link to="" onClick={() => handleAccess("/user/community")} className={`px-5 py-2 rounded-xl text-[#7B7B7B] font-semibold flex items-center ${location.pathname === "/user/community" ? "bg-gradient-to-r from-[#2D3728] to-[#507C37] text-white" : ""} `}>
                            <img src={DashboardIcon} alt="dashboarx-icon" />
                            Community
                        </Link>
                    </Flex>

                    {/* Button on the right */}
                    <Flex alignItems="center" margin="0 13px" display={{ base: 'none', md: 'flex' }}>
                        <GoBell className="text-[33px] shadow-xl rounded-[50%] p-2" />
                    </Flex>
                    {/* 
                    <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
                        <Box width="35px" height="35px" borderRadius="50%" background="#000">

                        </Box>
                        <Box margin="0 14px" >
                            <p className="font-semibold" >John Doe</p>
                            <p>Level 1</p>
                        </Box>
                    </Flex> */}
                    <Menu>
                        <MenuButton background="transparent" _focus={{ background: 'transparent' }} _hover={{ background: 'transparent' }} as={Button}>
                            <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
                                <Box width="35px" height="35px" borderRadius="50%" background="#000">

                                </Box>
                                <Box margin="0 14px" >
                                    <p className="font-semibold" >John Doe</p>
                                    <p>Level 1</p>
                                </Box>
                            </Flex>
                        </MenuButton>
                        <MenuList>
                            <MenuItem fontWeight="600" >Profile</MenuItem>
                            <MenuItem onClick={handleBillings} fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                            Billings
                            {
                                Stripe?.loading && <ClipLoader color="gray" size={25} />
                            }
                            </MenuItem>
                            <MenuItem onClick={handleLogout} color="red" fontWeight="600" >Logout</MenuItem>
                        </MenuList>
                    </Menu>

                    {/* Hamburger Icon for Mobile */}
                    <IconButton
                        size="md"
                        icon={isOpen ? <FaTimes /> : <FaBars />}
                        aria-label={isOpen ? 'Close menu' : 'Open menu'}
                        display={{ base: 'flex', md: 'none' }}
                        onClick={onToggle}
                    />
                </Flex>

                {/* Collapsible Menu Items for Mobile */}
                <Collapse in={isOpen}>
                    <Stack spacing={4} display={{ base: 'block', md: 'none' }} p={4}>
                        <Link to="user/dashboard" className="px-4 py-1 rounded-md text-[#253200] font-semibold">
                            Home
                        </Link>
                        <Link to="user/dashboard" className="px-4 py-1 rounded-md text-[#253200] font-semibold">
                            About
                        </Link>
                        <Link to="user/dashboard" className="px-4 py-1 rounded-md text-[#253200] font-semibold">
                            Services
                        </Link>
                        <Link to="user/dashboard" className="px-4 py-1 rounded-md text-[#253200] font-semibold">
                            Contact
                        </Link>
                    </Stack>
                </Collapse>
            </Box>


            <PlanTableModal isOpen={stripeModal}  onClose={() => setStripeModal(false)} />
        </>
    )
}

export default UserNavbar;
