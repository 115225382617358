import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FiSend } from "react-icons/fi";
import { BsChevronDown, BsPlusLg } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
// import useAnalytics from '../hooks/useAnalytics.tsx'
import useAutoResizeTextArea from "../hooks/useAutoResizeTextArea";
import Message from "./Message";
import { DEFAULT_OPENAI_MODEL } from "../shared/Constants";
import"../App.css"
import { useDispatch, useSelector } from "react-redux";
import { Conversation, GetAllAgents, setAgentName, setConverstions , UpdateChatName, GetAllChats, GetAssistantUser, CreateChat, ChatConversations  } from "app/chats/chats.action";
import { GetUser, resetUser } from "app/user/user.action";
import { Socket } from "Socket/socket";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { resetState } from "app/auth/auth.action";
import { USER_ACTIONS } from "app/user/user.constant";
import { BsPlus } from "react-icons/bs";
import AddFile from "./AddFile";

const Chat = (props: any) => {
  const { toggleComponentVisibility } = props;
  const params = useParams();
  const { chatId } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [agentsDropdown, setAgentsDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showEmptyChat, setShowEmptyChat] = useState(true);
  const [conversation, setConversation] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [modelIcon, setModelIcon] = useState("");
  const [open, setOpen] = useState(false)
  const [isConvo, setIsConvo] = useState(false)
  const [file, setFile] = useState<File | null>(null);
  const [fileInfo, setFileInfo] = useState<{ name: string; type: string;} | null>(null);

  const navigate = useNavigate()
  const formData = new FormData();
  
  const textAreaRef = useRef(null);

  const bottomOfChatRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const chats = useSelector((state: any) => state.chats);


  const handleConversations = async (e:any) => {
    setIsConvo(true);
    setIsLoading(true)
    const msg = message;
    setMessage("");
    const updatedConversation = [...chats.conversation , {role: "user" , content:msg , fileName:fileInfo?.name} , {role: "assistant" , content:""}];
    e.preventDefault();
    await dispatch(setConverstions(updatedConversation) as any)
    setFileInfo(null)
    setFile(null);
    await formData.append('chatId' , chatId);
    await formData.append('message' , msg);
    await formData.append('file' , file);
    await dispatch(ChatConversations(formData) as any)
    await dispatch(Conversation(chatId) as any)
    setIsLoading(false)
    setIsConvo(false);
  } 

  const handleFile = (e:any) => {
    e.preventDefault();
    setOpen(true);
  }

  // useEffect(() => {
  //   // Set up the WebSocket listener only once when the component mounts
  //   Socket.on(chatId, handleSocketData);
  //   return () => {
  //     // Clean up the WebSocket listener when the component unmounts
  //     Socket.off(chatId, handleSocketData);
  //   };
  // }, [chatId]);

  useEffect(() => {
    dispatch(Conversation(chatId) as any);
  }, [chatId]);

  useEffect(() => {
    if (chats.conversation) {
      setConversation(chats.conversation);
      setShowEmptyChat(chats.conversation.length === 0);
    }
  }, [chats.conversation]);

  useEffect(() => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  // const handleSocketData = (data: any) => {
  //   console.log("DATA: " , data)
  //   setConversation((prevData) => {
  //     const updatedConvo = [...prevData];
  //     updatedConvo[updatedConvo.length - 1].content += data;
  //     dispatch(setConverstions(updatedConvo) as any);  // Update the Redux store
  //     return updatedConvo;
  //   });
  // };

  // const sendMessage = async (e: any) => {
  //   e.preventDefault();

  //   // Don't send empty messages
  //   if (message.length < 1) {
  //     setErrorMessage("Please enter a message.");
  //     return;
  //   } else {
  //     setErrorMessage("");
  //   }

  //   setIsLoading(true);

  //   const newConvoObject = [
  //     ...chats.conversation,
  //     { content: message, role: "user" },
  //     { content: "", role: "assistant" },
  //   ];
  //   setConversation(newConvoObject);
  //   dispatch(setConverstions(newConvoObject) as any);

  //   // Clear the message & remove empty chat
  //   setMessage("");
  //   setShowEmptyChat(false);

  //   // Emit the startStream event
  //   Socket.emit("startStream", {
  //     data: message,
  //     chatId,
  //     messages: newConvoObject,
  //     // agentName: chats.assistant[0].model
  //   });

  //   setIsLoading(false);
  // };

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleConversations(e)
      e.preventDefault();
    } 
  };

  const handleLogout = () => {
    dispatch(resetState() as any);
    localStorage.removeItem("accesstoken");
    dispatch((resetUser()) as any);
    navigate("/")
  }

  useEffect(() => {
    dispatch(GetAssistantUser() as any);
  },[])

  useEffect(() => {
    dispatch(GetAllChats() as any);
  },[])


  useEffect(() => {
    if(chats?.chatList?.length > 0){
      navigate(`/chat/${chats?.chatList[0]?.id}`)
    }
  },[chats.chatList])

  // const handleAgent = async (name:any , icon:any) => {
  //   dispatch(setAgentName(name , icon) as any);
  //   setSelectedModel(name);
  //   setAgentsDropdown(false);
  //   const chat = chats.chatList.find((chat:any) => {
  //     if(chat.id === chatId){
  //       return chat
  //     }
  //   })
  //   await dispatch(UpdateChatName(chatId, chat.chatName , name) as any)
  //   await dispatch(GetAllChats() as any);
  // }

  useEffect(() => {
    dispatch(GetUser() as any); 
  },[chatId])

  useEffect(() => {
    setSelectedModel(chats?.agents[0]?.name);
    setModelIcon(chats?.agents[0]?.imageIcon)
    dispatch(setAgentName(chats?.agents[0]?.name , chats?.agents[0]?.imageIcon))
  },[chats.agents])

  // useEffect(() => {
  //   chats?.chatList?.map((chat:any) => {
  //     if(chat.id === chatId){
  //       setSelectedModel(chat?.AIAgent?.name)
  //       setModelIcon(chat?.AIAgent?.imageIcon);
  //       dispatch(setAgentName(chat?.AIAgent?.name , chat?.AIAgent?.imageIcon) as any)
  //     }
  //   })
  // },[chats.chatList])

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "24px";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [message]);

  return (
    <>
    <AddFile assistantId={chats?.assistant?.assistantId} open={open} setOpen={setOpen}  file={file} setFile={setFile} isAdmin={false} fileInfo={fileInfo} setFileInfo={setFileInfo} />
    <div className="flex max-w-full flex-1 flex-col">
    <div className="sticky top-0 z-10 flex items-center border-b border-white/20 bg-gray-800 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden">
        <button
          type="button"
          className="-ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"
          onClick={toggleComponentVisibility}
        >
          <span className="sr-only">Open sidebar</span>
          <RxHamburgerMenu className="h-6 w-6 text-white" />
        </button>
        <h1 className="flex-1 text-center text-base font-normal">+ New chat</h1>
        <button type="button" className="px-3">
          <BsPlusLg className="h-6 w-6" />
        </button>
      </div>
      <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
        <div className="flex-1 overflow-hidden">
          <div className="react-scroll-to-bottom--css-ikyem-79elbk h-full dark:bg-gray-50">
            <div className="react-scroll-to-bottom--css-ikyem-1n7m0yu bg-gray-50">
              {/* <div className="w-full flex justify-end p-5" >
              <a onClick={handleLogout} className="flex items-center px-5 py-2 items-center gap-3 transition-colors duration-200 font-semibold text-gray-100 rounded-3xl cursor-pointer text-sm bg-blue-500 shadow-lg">
            Log out
            <MdLogout className="h-4 w-4" />
          </a>
              </div> */}
              {!showEmptyChat && chats?.conversation.length > 0 ? (
                <div className="flex flex-col items-center text-sm py-10">
                  <div className="flex w-full items-center justify-center gap-1 border-black/10  p-3 text-gray-500  bg-gray-50 dark:text-gray-300">
                    {/* Agent: {selectedModel} */}
                  </div>
                  {chats?.conversation.map((message: any, index: any) => (
                    <Message
                      key={index}
                      message={message}
                      isLast={index === chats.conversation.length - 1}
                      setMessage={setMessage}
                      conversation={conversation}
                      setConversation={setConversation}
                      setShowEmptyChat={setShowEmptyChat}
                      chatId={chatId}
                      id={message.id}
                      isLoading={isLoading}
                      isConvo={isConvo}
                      setIsConvo={setIsConvo}
                      fileInfo={fileInfo}
                      setFileInfo={setFileInfo}
                     
                    />
                  ))}
                  <div className="w-full h-32 md:h-48 flex-shrink-0 bg-gray-50"></div>
                  <div ref={bottomOfChatRef}></div>
                </div>
              ) : null}
              {showEmptyChat ? (
                <div className="py-10 relative w-full flex flex-col h-full bg-gray-50">
                  <div className="flex items-center justify-center gap-2">
                    {/* <div className="relative w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                      <button
                        className="relative flex w-full cursor-pointer flex-col rounded-md border border-black/10 bg-white py-2 pl-3 pr-10 text-left focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 dark:border-white/20 dark:bg-gray-900 sm:text-sm align-center"
                        type="button"
                        onClick={() => setAgentsDropdown(!agentsDropdown)}
                      >
                        <label className="block text-xs text-gray-700 dark:text-gray-500 text-center">
                          Agent
                        </label>
                        <span className="inline-flex w-full truncate">
                          <span className="flex items-center gap-1 truncate text-white mt-1">
                            <img src={chats?.agentIcon} style={{marginRight:"10px" , borderRadius:"50%" , width:"35px" , height:"35px"}} alt="icon" />
                            {selectedModel}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <BsChevronDown className="h-4 w-4 text-gray-400" />
                        </span>
                      </button>
                      {
                        agentsDropdown && 
                        <div className="absolute top-[76px] w-full bg-gray-900 border-2 border-gray-400 p-4 rounded-lg" >
                        {
                          chats.agents?.map((agent:any , index:any) => {
                            return(
                              <li onClick={() => handleAgent(agent.name , agent.imageIcon)} className="flex items-center text-white border-b-2 border-gray-400 my-4 py-2 cursor-pointer" key={index}>
                                <img className="mr-2 w-[35px] h-[35px] rounded-[50%]" src={agent.imageIcon} />
                                {agent.name}
                              </li>
                            )
                          })
                        }
                        </div>
                      }
                    </div> */}
                  </div>
                  <h1 className="text-2xl sm:text-4xl font-semibold text-center text-gray-200 dark:text-gray-600 flex gap-2 items-center justify-center h-screen">
                  Live100 AI
                  </h1>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full dark:border-white/20 md:border-transparent md:dark:border-transparent bg-gray-50">
  <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
    <div className="relative flex flex-col h-full flex-1 md:flex-col">
      {errorMessage ? (
        <div className="mb-2 md:mb-0">
          <div className="h-full flex ml-1 md:w-full md:m-auto md:mb-2 gap-0 md:gap-2 justify-center">
            <span className="text-red-500 text-sm">{errorMessage}</span>
          </div>
        </div>
      ) : null}
      <div className="flex items-center py-2 flex-row w-full space-x-5 flex-grow md:py-2 md:pl-8 relative border-black/10 dark:border-gray-900/50 border-2 text-gray-500 rounded-[30px] shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] pl-5">
      <div className="flex w-[3%]">
          <button
            onClick={handleFile}
            className="absolute left-[14px] bottom-[7px]"
          >
          <BsPlus className="h-7 w-7 mr-1 mb-1 text-[#fff] rounded-md bg-gray-600 opacity-40"  />
          </button>
        </div>
          <small className="text-[#3b82f6]" >{fileInfo?.name  ? fileInfo?.name : ""}</small>
        <div className="w-[95%] pt-1 rounded-[30px]">
          <textarea
            ref={textAreaRef}
            value={message}
            tabIndex={0}
            style={{
              maxHeight: "150px",
              overflowY: "auto",
            }}
            placeholder="Send a message..."
            className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-2 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-2 md:pl-0 custom-scroll placeholder:text-gray-500"
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeypress}
          ></textarea>
        </div>
        <div className="flex w-[5%]">
          <button
            disabled={isLoading || message?.length === 0}
            onClick={handleConversations}
            className="absolute p-1 rounded-md bottom-3 md:bottom-3 pb-1 bg-gray-600 disabled:bg-gray-600 right-2 md:right-3 disabled:opacity-40"
          >
            <FiSend className="h-5 w-5 mr-1" color="white" />
          </button>
        </div>
      </div>
    </div>
  </form>
  <div className="px-3 pt-2 pb-3 bg-gray-50 text-center text-xs text-gray-500  md:px-4 md:pt-2 relative">
    <span className="text-[10px]">
      Live100 AI Chatbot may produce inaccurate information about people, places, or facts.
    </span>
  </div>
</div>


      </div>
    </div>
    </>
  );
};

export default Chat;
