import React from 'react';
import { useState , useEffect} from 'react';
import { Box, Flex, Heading, Link, Button, useDisclosure, IconButton, Stack, Collapse } from '@chakra-ui/react';
import LiveLogo from '../assets/white-logo.svg';
import darkLogo from '../assets/dark-logo.png'
import { FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import LoginModal from './LoginModal';
import SignupModal from './SignupModal';

interface NavbarProps {
  bgColor: string;
  textColor: string;
  isDarkLogo: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ bgColor , textColor , isDarkLogo}) => {
  const { isOpen, onToggle } = useDisclosure(); 
  const navigate = useNavigate();

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  // Handlers to toggle between modals
  const openLoginModal = () => {
    setIsLoginOpen(true);
    setIsSignupOpen(false);
  };

  const openSignupModal = () => {
    setIsSignupOpen(true);
    setIsLoginOpen(false);
  };

  useEffect(() => {
    if (isLoginOpen || isSignupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isLoginOpen, isSignupOpen]);
  
  return (
    <Box color={textColor} bg={bgColor}  py={{ base: 5, md: 4 }} px={20}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        {/* Logo on the left */}
        <Heading size="md">
          <img src={isDarkLogo ? darkLogo : LiveLogo} alt="logo" />
        </Heading>

        {/* Centered Menu Items */}
        <Flex
          display={{ base: 'none', md: 'flex' }}
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
        >
          {/* <Link px={4} py={1} rounded="md" href="#" fontWeight={700} color="#253200">
            Our mission
          </Link>
          <Link px={4} py={1} rounded="md" href="#" fontWeight={700} color="#253200">
            How We Help
          </Link>
          <Link px={4} py={1} rounded="md" href="#" fontWeight={700} color="#253200">
            Our Vision
          </Link>
          <Link px={4} py={1} rounded="md" href="#" fontWeight={700} color="#253200">
            Our Commitment
          </Link> */}
        </Flex>

        {/* Button on the right */}
        {/* <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          <LoginModal />
        </Flex>
        
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
        <SignupModal/>
        </Flex> */}

        {/* Button on the right */}
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          <Button width="154px" height="49px" borderRadius="13px" bg="#fff" _hover={{bg:"#fff"}} onClick={openLoginModal}>
            Login
          </Button>
          <Button width="154px" height="49px" borderRadius="13px" bg="#A2D800" _hover={{bg:"#A2D800"}} onClick={openSignupModal} ml={8}>
            Sign Up
          </Button>
        </Flex>

        {/* Hamburger Icon for Mobile */}
        <IconButton
          size="md"
          icon={isOpen ? <FaTimes /> : <FaBars />}
          aria-label={isOpen ? 'Close menu' : 'Open menu'}
          display={{ base: 'flex', md: 'none' }}
          onClick={onToggle}
        />
      </Flex>

      {/* Collapsible Menu Items for Mobile */}
      <Collapse in={isOpen}>
        <Stack spacing={4} display={{ base: 'block', md: 'none' }} p={4}>
          {/* <Link px={2} py={1} rounded="md" href="#">
            Home
          </Link>
          <Link px={2} py={1} rounded="md" href="#">
            About
          </Link>
          <Link px={2} py={1} rounded="md" href="#">
            Services
          </Link>
          <Link px={2} py={1} rounded="md" href="#">
            Contact
          </Link> */}
           <Button width="154px" height="49px" onClick={openLoginModal} bg="#fff">
            Login
          </Button>
          <Button width="154px" height="49px" onClick={openSignupModal} bg="#A2D800" my={2}>
            Sign Up
          </Button>
        </Stack>
      </Collapse>

       {/* Modals */}
      <LoginModal isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} onSignupClick={openSignupModal} />
      <SignupModal isOpen={isSignupOpen} onClose={() => setIsSignupOpen(false)} onLoginClick={openLoginModal} />
    </Box>



  );
};

export default Navbar;