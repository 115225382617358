import React , {useEffect} from "react"
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
    Text
} from '@chakra-ui/react'
import Chart from "chart.js/auto"
import { useDispatch , useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";
import { LuSend } from "react-icons/lu";
import PlusIcon from '../assets/plus-icon.svg';
import CrownImg from '../assets/Crown.png';
import PremiumIcon from '../assets/premium.png';
import NeedleImg from '../assets/needle.svg';

import UserNavbar from "./UserNavbar"
import CircularProgress from "./CircularProgress";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import { GetUser } from "app/user/user.action";

const UserDashboard = () => {

    const dispatch = useDispatch();

    const color = [
        "#CA0000",
        "#FF8000",
        "#FFFF00",
        "#00C061",
        "#54A106",
    ]

    let BackColor = color.slice(0, color.length)
    const data = {
        labels: ['check1', 'check2', 'check3', 'check4', 'check5'],
        datasets: [{
            label: "Amount",
            backgroundColor: BackColor,
            data: ['20', '20', '20', '20', '20']
        },
        ]
    }

    useEffect(() => {
        dispatch(GetUser() as any); 
      },[])
    return (
        <>
            <div className="bg-[#F5F5F5] min-h-[100vh]" >
                <UserNavbar />

                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between min-h-[180px] my-3">
                    <div className="w-full md:w-[52%] flex flex-col md:flex-row items-center justify-between bg-red-200 bg-gradient-to-r from-[#2D3728] to-[#507C37] rounded-[15px] py-3 px-12 text-white">
                        <h1 className="text-[32px] font-bold" >Your Total Score</h1>
                        <div className="flex flex-col md:flex-row">
                            <div className="mx-5 text-center" >
                                <small>Your level</small>
                                <CircularProgress value={70} pathColor="#83FF06" score="48" text="Level 1" width={150} height={80} />
                            </div>
                            <div className="text-center" >
                                <small>Target Score</small>
                                <h1 className="text-[62px] font-bold bg-gradient-to-b from-[#FFFFFF] to-[#FFF624] bg-clip-text text-transparent" >60</h1>
                                <small>Level 2</small>
                                <p>How to improve?</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[22%] bg-white rounded-[15px] border-2 border-[#EBEBEB] my-3 md:my-0" >
                        <Menu>
                            <MenuButton as={Button} background="transparent" boxShadow="none" _hover={{ background: "transparent" }} rightIcon={<MdExpandMore className="text-[22px]" />}>
                                <Text
                                    bgGradient="linear(to-l, #507C37, #232323)"
                                    bgClip="text"
                                    fontWeight="bold"
                                >
                                    Weekly
                                </Text>
                            </MenuButton>
                            <MenuList>
                                <MenuItem>Download</MenuItem>
                                <MenuItem>Create a Copy</MenuItem>
                            </MenuList>
                        </Menu>
                        <LineChart />
                    </div>
                    <div className="w-full md:w-[22%] rounded-[15px] flex flex-col justify-between my-2  md:my-0" >
                        <div className="bg-white py-4 px-10 rounded-[13px] shadow-lg font-bold flex items-center justify-between h-[77px]  my-2  md:my-0" >Add Food Details <img src={PlusIcon} alt="plus-icon" /> </div>
                        <div className="bg-white py-4 px-10 rounded-[13px] shadow-lg font-bold flex items-center justify-between h-[77px]  my-2  md:my-0" >Add Exercise Details <img src={PlusIcon} alt="plus-icon" /> </div>
                    </div>
                </section>

                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between min-h-[180px] my-3">
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Total Energy Expenditure (TEE)</h1>
                        <div className="min-h-[240px] mt-6 pt-3 flex justify-center">
                            <CircularProgress score="3573" text="Daily Caloric Intake" value={90} width={230} height={150} gradiant="bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" pathColor="#54A106" />
                        </div>
                        <div className="my-3 min-h-[130px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Exercise 5-6 times a week, including intensive cardio and strength training</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small >Nutrition</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Fitness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Wellness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Vegetable/Fruit Intake</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]"/>
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >3</h1>
                                        <small>Servings</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[130px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Maintain daily intake of fruits/vegetables, send client to summary about importance of increasing fruit/vegetable intake for prevention of chronic diseases.</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small >Nutrition</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Fitness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Wellness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Fats/Proteins Intake</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]"/>
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >4</h1>
                                        <small>Servings</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[130px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>This is reasonable for someone that is very physically active/athlete and/or has increased needs such as an eledery person (60+) or a pregnant woman</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small >Nutrition</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Fitness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Wellness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between min-h-[180px] my-3">
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Processed Foods</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]"/>
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >3</h1>
                                        <small>Servings</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[150px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>This is reasonable for someone that is very physically active/athlete and/or has increased needs such as an eledery person (60+) or a pregnant woman</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small >Nutrition</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Fitness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Wellness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Sleep/Stress</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]"/>
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >3</h1>
                                        <small>hours</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[150px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Severe sleep deprivation: guide client to information about toll that lack of sleep has on health and development of chronic diseases, include relaxation and stress management techniques including increased</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small >Nutrition</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Fitness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-2 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Wellness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Water /Staying Hydrated</h1>
                        <div className="min-h-[240px] mt-6 pt-4 flex justify-center">
                            <CircularProgress score="6" text="Cups" value={30} width={230} height={150} pathColor="#FF8000" />
                        </div>
                        <div className="my-3 min-h-[150px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Educate client about the core importance of staying well hydrated, toll it takes on health if not.</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small >Nutrition</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Fitness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                                <div className="flex items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold" ><small>Wellness</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:py-5 md:py-4 px-0 md:px-20 w-full my-3">
                    <div className="bg-gradient-to-l from-[#507C37] to-[#232323] py-12 flex flex-col items-center" >
                        <h1 className="text-[18px] font-medium text-[#ffffff] flex items-center text-center" >
                            <img src={PremiumIcon} alt="premium" className="mr-2" />
                            PREMIUM
                        </h1>
                        <h1 className="text-[18px] text-[#ffffff] text-[65px] font-bold text-center">Hit your macro goals!</h1>
                        <h1 className="text-[18px] text-[#ffffff] text-[18px] font-medium text-center">Find your ideal balance of carbs, fat, and protein.</h1>
                        <button className="rounded-[13px] bg-[#fff] text-[#253200] mt-5 px-6 py-3 font-bold" >GO PREMIUM</button>
                    </div>
                </section>

                <footer className="bg-white py-10" >
                    <div className="flex items-center justify-center flex-wrap sm:w-[90%] md:w-[65%] text-[#0054FF] mx-auto" >
                        <Link to="" className="mx-2"  >Calorie Counter</Link>
                        <Link to="" className="mx-2">Blog</Link>
                        <Link to="" className="mx-2">Terms</Link>
                        <Link to="" className="mx-2">Privacy</Link>
                        <Link to="" className="mx-2">Contact Us</Link>
                        <Link to="" className="mx-2">API</Link>
                        <Link to="" className="mx-2">Jobs</Link>
                        <Link to="" className="mx-2">Feedback</Link>
                        <Link to="" className="mx-2">Community Guidelines</Link>
                        <Link to="" className="mx-2">Cookie</Link>
                        <Link to="" className="mx-2">Preferences</Link>
                        <Link to="" className="mx-2">Ad Choices</Link>
                        <Link to="" className="mx-2">Do Not Sell My Personal Information</Link>
                    </div>

                    <div className="mt-4" >
                        <p className="text-center text-[#000]" >© 2024 Live100, Inc.</p>
                    </div>

                </footer>


            </div>
        </>
    )
}

export default UserDashboard