import React, { useState, useEffect, useRef } from "react";
import { SiOpenai } from "react-icons/si";
import { TbCursorText } from "react-icons/tb";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Markdown from 'react-markdown'
import { AiFillFile } from "react-icons/ai";


const Message = (props: any) => {

  const { message, isLast, setConversation, chatId, setMessage, setShowEmptyChat, conversation, id, selectedModel, isLoading, isConvo, setIsConvo , fileInfo, setFileInfo} = props;
  let { role, content: text , fileName } = message;

  const [typedContent, setTypedContent] = useState("");

  const isUser = role === "user";

  const dispatch = useDispatch();
  const chats = useSelector((state: any) => state.chats)

  // const [isLoading, setIsLoading] = useState(false);
  const [copyId, setCopyId] = useState(null);


  const handleChatCopy = (text: any, id: any) => {
    navigator.clipboard.writeText(text);
    setCopyId(id);
    toast.success("Copied!")
  }


  // const handleRegen = () => {
  //   setIsLoading(true);

  //   const newConversation = chats.conversation.filter((_: any, index: any) => index !== chats.conversation.length - 1);
  //   setConversation(newConversation);
  //   setShowEmptyChat(newConversation.length === 0);

  //   const regenConvo = chats.conversation.slice(0, -2);

  //   const newConvoObject = [...regenConvo,
  //   { content: newConversation[newConversation.length - 1].content, role: "user" },
  //   { content: "", role: "assistant" }
  //   ];

  //   dispatch(setConverstions(newConvoObject) as any);
  //   setConversation(newConvoObject)

  //   Socket.emit("regenerate-stream", {
  //     data: newConversation[newConversation.length - 1].content,
  //     chatId,
  //     messages: newConversation,
  //     agentName:chats.agentName
  //   })

  //   // Clean up previous listeners before adding a new one
  //   Socket.off(chatId);

  //   Socket.on(chatId, (data: any) => {
  //     setConversation((prevData: any) => {
  //       // Ensure to create a new array to trigger the state update
  //       const updatedConvo = [...prevData];
  //       updatedConvo[updatedConvo.length - 1].content += data;
  //       dispatch(setConverstions(updatedConvo) as any);
  //       return updatedConvo;
  //     });
  //   });
  //   setIsLoading(false);
  // }

  useEffect(() => {
    setTimeout(() => {
      setCopyId("");
    }, 3000)
  }, [copyId])


  const originalContent = useRef(text);
  const hasRun = useRef(false);

  const typeText = (TEXT: string) => {
    let currentText = "";
    const typingSpeed = 5;
    let idx = 0;

    if(isConvo){
      const typeInterval = setInterval(() => {
        currentText += TEXT[idx];
        setTypedContent(currentText);
        idx++;
        if (idx === TEXT.length) {
          clearInterval(typeInterval);
          setTypedContent(text)
          hasRun.current = false;
        }
      }, typingSpeed);
    }
    else{
      setTypedContent(TEXT);
    }
  };

  useEffect(() => {
    if (text && !hasRun.current) {
      hasRun.current = true;
      const cleanedContent = text
        ?.replace(/\\n/g, '\n')
        .replace(/```markdown|```/g, ""); 
        typeText(cleanedContent);
    }
  }, [text]);

  useEffect(() => {
    if(fileName){
      setFileInfo(null);
    }
  },[fileName])
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 2000,
          style: {
            background: '#cdcdcd',
            color: '#fff',
          },
          success: {
            duration: 2000,
            iconTheme: {
              primary: 'green',
              secondary: 'black',
            },
          },
          error: {
            duration: 2000,
            iconTheme: {
              primary: 'red',
              secondary: 'black',
            },
          },
        }}
      />
      <div
        className={`group w-full text-gray-800 dark:text-gray-100  bg-gray-50 "
        }`}
      >
        <div className="text-base gap-4 md:gap-6 md:max-w-md lg:max-w-xl xl:max-w-3xl flex lg:px-0 m-auto w-full justify-end md:pl-0 pl-0 md:pr-0 pr-5">
          <div className={`inline-flex flex-row my-4 gap-5 md:gap-6 md:max-w-2xl p-4 md:py-6 lg:px-8 min-w-[25%] justify-start  ${isUser ? "flex-row-reverse bg-gray-100 rounded-xl my-1" : "lg:max-w-xl xl:max-w-3xl w-full bg-gray-100 rounded-xl"}`}>
            <div className="w-8 flex flex-col relative items-end">
              <div className="relative h-8 w-8 p-1 rounded-[50%] text-white flex items-center justify-center bg-gray-600 text-opacity-100 shadow-xl">
                {isUser ? (
                  <AiOutlineUser className="h-5 w-5 text-white" />
                ) : (
                  <SiOpenai className="h-5 w-5 text-white" />
                )}
              </div>
              <div className="text-xs flex items-center justify-center gap-1 absolute left-0 top-2 -ml-4 -translate-x-full group-hover:visible !invisible">
                <button
                  disabled
                  className="text-gray-300 dark:text-gray-400"
                ></button>
                <span className="flex-grow flex-shrink-0">1 / 1</span>
                <button
                  disabled
                  className="text-gray-300 dark:text-gray-400"
                ></button>
              </div>
            </div>
            <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3">
              <div className="flex flex-grow flex-col gap-3">
                <div className={`min-h-15 flex flex-col items-start gap-4 whitespace-pre-wrap break-words`}>
                  <div className={`markdown prose break-words dark:prose-invert dark ${isUser ? "text-end w-full" : "w-full"}`} >
                    {!isUser && text === null ? (
                      <TbCursorText className="h-6 w-6 animate-pulse" />
                    ) : (
                      <>
                        <div style={{ color: isUser ? "#3b82f6" : "#222222" }} className={`w-full markdown-div`} >
                          {
                            isLast && isLoading ?
                              <div className="flex justify-start items-center">
                                <div className="flex space-x-2 pt-3">
                                  <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.25s]'></div>
                                  <div className='h-2 w-2 bg-gray-600 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                                  <div className='h-2 w-2 bg-gray-700 rounded-full animate-bounce'></div>
                                </div>
                              </div>
                              :
                              <>   
                                {
                                  isLast ?
                                    <>
                                      <Markdown>{typedContent}</Markdown>
                                    </>
                                    :
                                    <>
                                      <Markdown>{text}</Markdown>
                                    </>
                                }
                              </>
                          }
                          {/* {
                            (isUser && conversation?.fileName) &&
                            <>
                            <small className="flex items-center font-semibold" ><AiFillFile className='text-[16px] mr-1' />{conversation?.fileName}</small>
                            </>
                          } */}
                          {
                            (isUser && fileName) && 
                            <small className="flex items-center font-semibold" ><AiFillFile className='text-[16px] mr-1' />{fileName}</small>
                          }
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-start">
                {(!isUser && text?.length > 0 && !chats.loading) && (
                  <>
                    <small
                      className="bg-gray-600 text-white rounded-lg px-5 py-1 flex items-center cursor-pointer"
                      onClick={() => handleChatCopy(text, id)}
                    >
                      <MdContentCopy className="mr-2 text-[14px] w-[20px] h-[20px] flex-shrink-0" />
                      {copyId === id ? "Copied" : "Copy"}
                    </small>
                    {/* {isLast && (
                <small 
                  // onClick={handleRegen} 
                  className="bg-gray-900 rounded-lg px-3 py-1 ml-5 flex items-center cursor-pointer border-2 border-[#2f2f2f]"
                  style={{ minWidth: '130px', minHeight: '32px' }}
                >
                  <MdSettingsBackupRestore className="text-[14px] w-[20px] h-[20px] flex-shrink-0" />
                  <span className="ml-2 mr-2">Regenerate Response</span>
                </small>
              )} */}
                  </>
                )}
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
