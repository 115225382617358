import React, { useEffect } from "react";
import "./styles/globals.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatPage from "./components/ChatPage";
import SignUp from "./components/Signup";
import Login from "./components/Login";
import ForgotPass from "./components/ForgotPass";
import VerifyEmail from "components/VerifyEmail";
import ResetPass from "components/ResetPass";
import ProtectedRoute from "components/protectedRoutes";
import Admin from "components/Admin";
import UserManagement from "components/UserManagement";
import FileManagement from "components/FileManagement";
import Home from "landing-page/Home";
import Success from "components/Success";
import Onboard from "components/Onboard";
import OnboardStepper from "components/OnboardStepper";
import UserDashboard from "components/UserDashboard";

function App() {
  const ProtectedDashboard = ProtectedRoute(ChatPage);
  const ProtectedUserDashboard = ProtectedRoute(UserDashboard);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/chat/:chatId" element={<ProtectedDashboard />} />
        <Route path="/chat" element={<ProtectedDashboard />} />
        <Route path="/verify/:token" element={<VerifyEmail/>} />
        <Route path="/chat/:chatId" element={<ChatPage />} />
        <Route path="/chat" element={<ChatPage />} />

        {/* landing page */}
        <Route path="/" element={<Home/>} />

        {/* Auth Routes */}
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-pass" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPass />} />
        <Route path="/success" element={<Success />} />

        {/* Onboarding */}
        <Route path="/onboard" element={<Onboard />} />
        <Route path="/onboard-steps" element={<OnboardStepper />} />


         {/* Admin Routes */}
        <Route path="/admin" element={<Admin />}>
          <Route path="user-management" element={<UserManagement/>}/>
          <Route path="file-management" element={<FileManagement/>}/>
        </Route>
        
         {/* User Routes */}
        <Route path="/user">
          <Route path="dashboard" element={<ProtectedUserDashboard/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
